var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"relative"},[_c('div',{}),_vm._v(" "),_c('div',{staticClass:"flex flex-col items-center"},[_c('h2',{staticClass:"title title--hero text-center"},[_c('T',{attrs:{"t":"pricing.title"}})],1),_vm._v(" "),_c('div',{staticClass:"flex justify-center mt-8"},[_c('Button',{staticClass:"p-button-primary p-button-sm/md rounded-r-none",class:{ 'p-button-outlined': _vm.billingMode === 'annual' },attrs:{"label":_vm.$t('pricing.monthly_billing'),"type":"submit"},on:{"click":function($event){_vm.billingMode = 'monthly'}}}),_vm._v(" "),_c('Button',{staticClass:"p-button-primary p-button-sm/md rounded-l-none",class:{ 'p-button-outlined': _vm.billingMode === 'monthly' },attrs:{"label":_vm.$t('pricing.annual_billing'),"type":"submit"},on:{"click":function($event){_vm.billingMode = 'annual'}}})],1)]),_vm._v(" "),_c('div',{staticClass:"grid grid-cols-12 gap-5 mt-12 xl:max-w-[1250px] xl:mx-auto"},[_c('div',{staticClass:"col-span-12 lg:col-span-4"},[_c('SubscriptionPlanCard',{attrs:{"transition-delay":0,"title":_vm.$t('pricing.plans.starter.title'),"lead":_vm.$t('pricing.plans.starter.quota'),"billing-mode":_vm.billingMode,"btn-label":_vm.$t('core.register'),"features":[_vm.features.all_functionalities],"is-free":""}})],1),_vm._v(" "),_c('div',{staticClass:"col-span-12 lg:col-span-4"},[_c('SubscriptionPlanCard',{attrs:{"transition-delay":20,"title":_vm.$t('pricing.plans.standard.title'),"lead":_vm.$t('pricing.plans.standard.quota'),"billing-mode":_vm.billingMode,"pricing":{
                    annual: {
                        price: '825',
                        full_price: '9900',
                        annual_unit: _vm.$t('pricing.annual_unit'),
                        monthly_unit: _vm.$t('pricing.monthly_unit'),
                    },
                    monthly: {
                        price: '990',
                        unit: _vm.$t('pricing.monthly_unit'),
                    },
                },"btn-label":_vm.$t('generic.try_for_free'),"features":[_vm.features.all_starter_functionalities, _vm.features.free_trial]}})],1),_vm._v(" "),_c('div',{staticClass:"col-span-12 lg:col-span-4"},[_c('SubscriptionPlanCard',{attrs:{"transition-delay":40,"title":_vm.$t('pricing.plans.premium.title'),"lead":_vm.$t('pricing.plans.premium.quota'),"billing-mode":_vm.billingMode,"pricing":{
                    annual: {
                        price: '1250',
                        full_price: '15000',
                        annual_unit: _vm.$t('pricing.annual_unit'),
                        monthly_unit: _vm.$t('pricing.monthly_unit'),
                    },
                    monthly: {
                        price: '1490',
                        unit: _vm.$t('pricing.monthly_unit'),
                    },
                },"btn-label":_vm.$t('generic.get_in_touch_with_us'),"features":[
                    _vm.features.all_premium_functionalities,
                    _vm.features.free_trial,
                    _vm.features.api_access,
                    _vm.features.app_integrations ],"is-premium":""}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }