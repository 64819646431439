import { render, staticRenderFns } from "./SubscriptionPlanCard.vue?vue&type=template&id=c3b4b12e&scoped=true&"
import script from "./SubscriptionPlanCard.vue?vue&type=script&lang=js&"
export * from "./SubscriptionPlanCard.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c3b4b12e",
  null
  
)

export default component.exports